import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7dbad409"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "appfull"
};
const _hoisted_2 = {
  class: "subframe",
  scrolling: "no",
  frameborder: "0",
  src: "/arti/arti.html",
  ref: "subframe"
};
export default {
  __name: 'learn',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    let jsonuser = {};
    let learnid = 0;
    let jsonlearn = {};
    let lessoninfo = {};
    let exid = '';
    function getlesson() {
      let jsondata = {
        'postdata': {
          'act': 'te_course_learn',
          'sid': 12,
          'data': {
            'isagree': 1,
            'exid': exid
          }
        }
      };
      proxy.$common.post(jsondata, res => {
        if (res.isok) {
          lessoninfo = res.infodata;
          jsonuser.stuname = res.infodatame.username;
          jsonuser.stuuser = res.infodatame.realname;
          jsonuser.suspend = res.infodatalearn.suspend;
          learnid = res.infodatalearn.id;
          showarti();
        } else {
          proxy.$common.alert(res.qs >= 3 ? 'nomorechance' : 'noaccess');
        }
      });
    }
    function showarti() {
      jsonlearn = {};
      let artiurl = '/arti/' + lessoninfo.url + '/index_lms_html5.html';
      proxy.$nextTick(() => {
        let iframe = proxy.$refs.subframe;
        if (iframe.contentWindow.setdata == undefined) {
          if (iframe.attachEvent) {
            iframe.attachEvent("onload", () => {
              iframe.contentWindow.setdata(artiurl, jsonuser);
            });
          } else {
            iframe.onload = () => {
              iframe.contentWindow.setdata(artiurl, jsonuser);
            };
          }
        } else {
          iframe.contentWindow.setdata(artiurl, jsonuser);
        }
      });
    }
    function savedata() {
      let updata = '';
      if (proxy.$common.basic.isobjexist(jsonlearn)) {
        if (jsonlearn.act == 'arti') {
          updata = proxy.$common.basic.jsonclone(jsonlearn);
          updata.exid = exid;
          let jsondata = {
            'postdata': {
              'act': 'te_course_learn_save',
              'sid': 12,
              'id': learnid,
              'data': updata
            },
            'msg': ''
          };
          proxy.$common.post(jsondata, () => {});
        }
      }
    }
    function framemssage(e) {
      if (e.data.act == 'arti') {
        //返回值是是相关数据时才进行后续操作
        jsonlearn = e.data;
        savedata();
      }
    }
    onUnmounted(() => {
      savedata();
      window.removeEventListener('beforeunload', savedata);
      window.removeEventListener('message', framemssage);
    });
    onMounted(() => {
      exid = route.params.ex;
      getlesson();
      window.addEventListener('message', framemssage);
      window.addEventListener('beforeunload', savedata);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("iframe", _hoisted_2, null, 512)]);
    };
  }
};